import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { addMonths, differenceInCalendarMonths, format } from 'date-fns'
import { get, round } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import useVerifyRole from '../../hooks/verifyRole'
import SEO from '../../components/SEO'
import { Form, Select } from '../../_shared/components'
import Hero from '../../components/Hero'
import Section from '../../components/Section'

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])
  const [year, setYear] = useState(new Date().getFullYear())
  const [week, setWeek] = useState(Number(format(new Date(), 'w')))

  const { data } = useQuery(
    gql`
      query GetStats($year: Int!, $week: Int!) {
        weekStatistics(year:$year week:$week) {
          newUsers
          totalUsers
          activeUsers
          activeVippsUsers
          activeInAppUsers
          activeVoucherUsers
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      variables: { week, year }
    },
  )

  if (loading || !hasRole) {
    return null
  }

  return (
    <>
      <SEO title="Rapporter" />

      <Hero
        title={
          <>
            <Link to="/admin">Admin</Link> &gt; Statistikk
          </>
        }
        type={['center', 'small']}
      />
      <Section size="small">

        <form onSubmit={({ preventDefault }) => preventDefault} style={{textAlign:"center"}}>
          <Select
            value={week}
            options={new Array(53).fill(null).map((_, index) => ({
              value: index + 1,
              name: `Uke ${index + 1}`
            }))}
            onChange={val => setWeek(Number(val))}
          />

          {' '}

          <Select
            value={year}
            options={new Array((new Date().getFullYear() - 2020) + 1).fill(null).map((_, index) => ({
              value: 2021 - index,
              name: 2021 - index
            }))}
            onChange={val => setYear(Number(val))}
          />
        </form>

        <table style={{width: '400px', maxWidth:'100%', margin: '1rem auto'}}>
          <thead>
            <tr>
              <td colspan="2"></td>
              <th scope="col" align="left">Antall</th>
              <th scope="col" align="left">Andel</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colspan="2" scope="row" align="left" width="50">Nye brukere</th>
              <td>{data?.weekStatistics?.[0]?.newUsers}</td>
              <td>-</td>
            </tr>
            <tr>
              <th colspan="2" scope="row" align="left">Brukere totalt</th>
              <td>{data?.weekStatistics?.[0]?.totalUsers}</td>
              <td>-</td>
            </tr>
            <tr>
              <th colspan="2" scope="row" align="left">Aktive brukere</th>
              <td>{data?.weekStatistics?.[0]?.activeUsers}</td>
              <td>-</td>
            </tr>
            <tr>
              <td width="10"></td>
              <th scope="row" align="left">Vipps</th>
              <td>{data?.weekStatistics?.[0]?.activeVippsUsers}</td>
              <td>{round(100 * (data?.weekStatistics?.[0]?.activeVippsUsers / data?.weekStatistics?.[0]?.activeUsers), 2)}%</td>
            </tr>
            <tr>
              <td></td>
              <th scope="row" align="left">In-app</th>
              <td>{data?.weekStatistics?.[0]?.activeInAppUsers}</td>
              <td>{round(100 * (data?.weekStatistics?.[0]?.activeInAppUsers / data?.weekStatistics?.[0]?.activeUsers), 2)}%</td>
            </tr>
            <tr>
              <td></td>
              <th scope="row" align="left">Gavekort</th>
              <td>{data?.weekStatistics?.[0]?.activeVoucherUsers}</td>
              <td>{round(100 * (data?.weekStatistics?.[0]?.activeVoucherUsers / data?.weekStatistics?.[0]?.activeUsers), 2)}%</td>
            </tr>
          </tbody>
        </table>

      </Section>
    </>
  )
}
